import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { ClientOnly } from "remix-utils/client-only";

function TooltipContainer(props: PropsWithChildren<{ positionY: string, positionX: string, oneLine?: boolean, visible: boolean }>) {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [keepOpen, setKeepOpen] = useState(false);

  useEffect(() => {
    if (tooltipRef.current) {

      tooltipRef.current.style.left = props.positionX;
      tooltipRef.current.style.top = props.positionY;
      tooltipRef.current.style.right = "auto";
      tooltipRef.current.style.bottom = "auto";

      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      if (tooltipRect.x < 10) {
        tooltipRef.current.style.left = "10px";
        tooltipRef.current.style.right = "auto";
      }
      if (tooltipRect.x + tooltipRect.width + 10 > window.outerWidth) {
        tooltipRef.current.style.right = "10px";
        tooltipRef.current.style.left = "auto";
      }
      if (tooltipRect.y < 10) {
        tooltipRef.current.style.top = "10px";
        tooltipRef.current.style.bottom = "auto";
      }
      if (tooltipRect.y + tooltipRect.height + 10 > window.outerHeight) {
        tooltipRef.current.style.bottom = "10px";
        tooltipRef.current.style.top = "auto";
      }
    }

  }, [tooltipRef.current?.id, props.visible, props.positionX, props.positionY]);

  if (document.body) {
    return ReactDOM.createPortal(
      <div ref={tooltipRef}
           onMouseEnter={() => {
             setKeepOpen(true);
           }}
           onMouseLeave={() => {
             setKeepOpen(false);
           }}
           className={`transition-[opacity,visibility] duration-200 ${(props.visible || keepOpen) ? "opacity-100 visible" : "opacity-0 invisible"} fixed z-40 ${props.oneLine ? "whitespace-nowrap" : "w-[500rem] max-w-sm"} bg-gray-900/70 backdrop-blur px-6 py-4 rounded-lg shadow-md`}>
        <div className="text-base font-medium text-white inline-block">
          {props.children}
        </div>
      </div>
      , document.body);
  }
  return <></>;
}

const Tooltip = (props: PropsWithChildren<{ oneLine?: boolean, parent: ReactElement }>) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);

  return <>
    {React.cloneElement(props.parent, {
      ...props.parent.props,
      className: (props.parent.props.className??"") + " cursor-help",
      onMouseEnter: (e: any) => {
        const rect = e.target.getBoundingClientRect();
        setPositionX(rect.left);
        setPositionY(rect.top + 40);
        setTooltipVisible(true);
      },
      onMouseLeave: () => {
        setTooltipVisible(false);
      }
    })}
    <ClientOnly fallback={<div></div>}>
      {() =>
        <TooltipContainer visible={tooltipVisible} positionX={positionX + "px"}
                          positionY={positionY + "px"}>{props.children}</TooltipContainer>
      }
    </ClientOnly>
  </>;


};

export default Tooltip;
